import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CustomModal from './CustomModal'
import TCSanitizeHTML from '../TCSanitizeHTML'

const BrigadeLabelRequestModal = ({
  actions = [],
  additionalClassName,
  imageUrl,
  isOpen = false,
  name,
  onClose = () => {},
  renderActions,
  text,
  type = 'success',
}) => {
  const textClass = `brigade-modal__text ${type === 'error' ? 'has-error' : ''}`

  const memoizedActions = useMemo(() => actions, [actions])

  return (
    <CustomModal
      additionalClassName={additionalClassName}
      onClose={onClose}
      open={isOpen}
    >
      <div className="brigade-modal__header">
        <img
          alt={`Image for ${name}`}
          className="brigade-modal__image"
          height="130"
          src={imageUrl}
          width="auto"
        />
        <h2 className="brigade-modal__title" id="custom-modal-title">
          {name}
        </h2>
      </div>
      <div
        aria-labelledby="custom-modal-title"
        className={textClass}
        id="custom-modal-description"
        role="document"
      >
        <TCSanitizeHTML html={text} />
      </div>
      {renderActions
        ? renderActions(memoizedActions)
        : memoizedActions.length > 0 && (
            <div className="brigade-modal__actions">
              {memoizedActions.map((action) => {
                if (action.url) {
                  return (
                    <a
                      className={
                        action.buttonClass ? `${action.buttonClass}` : ''
                      }
                      href={action.url}
                      key={action.id}
                    >
                      {action.text}
                    </a>
                  )
                } else {
                  return (
                    <button
                      className={
                        action.buttonClass ? `${action.buttonClass}` : ''
                      }
                      key={action.id}
                      onClick={action.onClick}
                      type="button"
                    >
                      {action.text}
                    </button>
                  )
                }
              })}
            </div>
          )}
    </CustomModal>
  )
}

BrigadeLabelRequestModal.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string,
      text: PropTypes.string.isRequired,
      buttonClass: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  additionalClassName: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  renderActions: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error']),
}

export default BrigadeLabelRequestModal
