import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DownloadResourcesDropdown from '../DownloadResourcesDropdown'
import LabelButtonGroup from './LabelButtonGroup'
import { writeProgramHistory } from './utils/profileProgramUtils'

const LabelButtons = ({
  brigadeLabelLimitIsReached,
  collectionLabelLimitIsReached,
  downloadResourcesPath,
  handleLabelRequest,
  isLoading,
  isShowingLabels,
  labels,
  shipmentsPath,
  shippingModelData,
  t,
  toggleLabel,
}) => {
  const handleProgramHistoryClick = useCallback(() => {
    writeProgramHistory(shipmentsPath)
  }, [shipmentsPath])

  return (
    <div className="activeCollection_buttonWrapper mb-4">
      {labels.length > 0 && (
        <div className="activeCollection_multipleButtons">
          <button
            className={`${
              isShowingLabels ? 'open ' : ''
            }viewLabel activeCollection_requestLabelLink buttonComponent--small me-5`}
            onClick={toggleLabel}
          >
            {t['tcred.profile.my_programs.view_shipping_labels']}
          </button>
        </div>
      )}

      {labels.length === 0 && (
        <LabelButtonGroup
          brigadeLabelLimitIsReached={brigadeLabelLimitIsReached}
          collectionLabelLimitIsReached={collectionLabelLimitIsReached}
          handleLabelRequest={handleLabelRequest}
          isLoading={isLoading}
          shippingModelData={shippingModelData}
          t={t}
        />
      )}

      {downloadResourcesPath !== null && (
        <DownloadResourcesDropdown
          downloadResourcesPath={downloadResourcesPath}
          t={t['tcred.collection.download_resources']}
        />
      )}
      <a
        className="activeCollection_historyLink readMoreLink"
        href="#program-activity"
        onClick={handleProgramHistoryClick}
      >
        {t['tcnext.see_shipment_history']}
      </a>
    </div>
  )
}

LabelButtons.propTypes = {
  brigadeLabelLimitIsReached: PropTypes.bool.isRequired,
  collectionLabelLimitIsReached: PropTypes.bool.isRequired,
  downloadResourcesPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  handleLabelRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isShowingLabels: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
  shipmentsPath: PropTypes.string.isRequired,
  shippingModelData: PropTypes.array.isRequired,
  t: PropTypes.object.isRequired,
  toggleLabel: PropTypes.func.isRequired,
}

export default LabelButtons
