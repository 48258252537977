import React from 'react'
import PropTypes from 'prop-types'

const BrigadeHeader = ({ brigadeDescription, brigadePath, name, t }) => {
  return (
    <>
      <div className="activeCollection_lable active">
        <span>{t['tcred.collection.active']}</span>
      </div>
      <div className="activeCollection_headline">
        <h5 className="activeCollection_title">{name}</h5>
        <a
          className="activeCollection_titleLink readMoreLink"
          href={brigadePath}
        >
          {t['brigades.learn_more']}
        </a>
      </div>
      <p className="activeCollection_description">{brigadeDescription}</p>
    </>
  )
}

BrigadeHeader.propTypes = {
  brigadeDescription: PropTypes.string,
  brigadePath: PropTypes.string,
  name: PropTypes.string,
  t: PropTypes.object.isRequired,
}

export default BrigadeHeader
