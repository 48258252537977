import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import BrigadeLabelRequestModal from './BrigadeLabelRequestModal'

const BrigadeLabelRequestModalWrapper = ({
  actions = [],
  imageUrl,
  name,
  text,
  type = 'success',
  sendPostOnClose = false,
  postUrl = '',
}) => {
  const [isLabelRequestModalOpen, setIsLabelRequestModalOpen] = useState(false)

  useEffect(() => {
    if (text) {
      setIsLabelRequestModalOpen(true)
    }
  }, [text])

  const handleCloseModal = async () => {
    setIsLabelRequestModalOpen(false)
    if (sendPostOnClose && postUrl) {
      try {
        await axios.post(
          postUrl,
          {},
          {
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
                .content,
            },
          },
        )
      } catch (error) {
        console.error('Error sending Session clear POST request:', error)
      }
    }
  }

  return (
    <BrigadeLabelRequestModal
      actions={actions}
      imageUrl={imageUrl}
      isOpen={isLabelRequestModalOpen}
      name={name}
      onClose={handleCloseModal}
      text={text}
      type={type}
    />
  )
}

BrigadeLabelRequestModalWrapper.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      buttonClass: PropTypes.string,
      url: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  postUrl: PropTypes.string,
  sendPostOnClose: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']),
}

export default BrigadeLabelRequestModalWrapper
