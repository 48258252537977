import React from 'react'
import PropTypes from 'prop-types'
import LabelRequestButtons from './LabelRequestButtons'

const LabelTable = ({
  brigadeLabelLimitIsReached,
  collectionLabelLimitIsReached,
  handleLabelRequest,
  isLoading,
  isShowingLabels,
  labels,
  renderDownloadLabelButton,
  shippingModelData,
  t,
}) => {
  if (!labels.length || !isShowingLabels) {
    return null
  }

  return (
    <>
      <div className="tableComponent">
        <table className="tableComponent_table">
          <thead>
            <tr>
              <th>{t['tcred.profile.my_programs.download_label']}</th>
              <th>{t['tcred.profile.my_programs.date_requested']}</th>
              <th>{t['tcred.profile.shipments_history.tracking_number']}</th>
            </tr>
          </thead>
          <tbody>
            {labels.map((label) => (
              <tr key={label.tracking_number}>
                <td>{renderDownloadLabelButton(label.url)}</td>
                <td>{label.created_at}</td>
                <td>{label.tracking_number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <LabelRequestButtons
        brigadeLabelLimitIsReached={brigadeLabelLimitIsReached}
        collectionLabelLimitIsReached={collectionLabelLimitIsReached}
        handleLabelRequest={handleLabelRequest}
        isLoading={isLoading}
        shippingModelData={shippingModelData}
        t={t}
      />
    </>
  )
}

LabelTable.propTypes = {
  brigadeLabelLimitIsReached: PropTypes.bool.isRequired,
  collectionLabelLimitIsReached: PropTypes.bool.isRequired,
  handleLabelRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isShowingLabels: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
  renderDownloadLabelButton: PropTypes.func.isRequired,
  shippingModelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.object.isRequired,
}

export default LabelTable
