import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

const CustomModal = ({ additionalClassName, children, onClose, open }) => {
  return (
    <Modal
      aria-describedby="custom-modal-description"
      aria-labelledby="custom-modal-title"
      className={`modal-material ${additionalClassName || ''}`}
      onClose={onClose}
      open={open}
    >
      <Box className="modal-dialog modal-dialog-centered">
        <Box className="modal-content">
          <Box className="modal-body">
            <button
              aria-label="Close Modal"
              className="closeModalButton"
              onClick={onClose}
              title="Close Modal"
            >
              <span>+</span>
            </button>
            {children}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

CustomModal.propTypes = {
  additionalClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CustomModal
