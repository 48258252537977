import React from 'react'
import PropTypes from 'prop-types'

const MainMenuExpanded = ({ isShowing, mainNavItems, groups }) => {
  const classNames = isShowing ? 'mainCategoryNav' : 'mainCategoryNav d-none'

  return (
    <div className={classNames}>
      <div className="mainCategoryNav_container">
        <div className="mainCategoryNav_row">
          {mainNavItems.map(mainNavItem => (
            <div className="mainCategoryNav_mainNavItem" key={mainNavItem.title}>
              <a className="mainCategoryNav_mainNavLink" href={mainNavItem.href}>
                {mainNavItem.title}
              </a>
            </div>
          ))}

          {groups.map(group => (
            <div className="mainCategoryNav_group" key={group.title}>
              <h3 className={`mainCategoryNav_groupTitle--${group.name}`}>
                {group.title}
              </h3>
              <ul className="mainCategoryNav_groupList">
                {group.items.map(item => (
                  <li className="mainCategoryNav_groupItem" key={item.title}>
                    <a className="mainCategoryNav_groupLink" href={item.href} target={item.target}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

MainMenuExpanded.propTypes = {
  groups: PropTypes.array,
  isShowing: PropTypes.bool,
  mainNavItems: PropTypes.array
}

export default MainMenuExpanded
