import React from 'react'
import PropTypes from 'prop-types'
import LabelButtonGroup from './LabelButtonGroup'

const LabelRequestButtons = (props) => {
  return <LabelButtonGroup {...props} />
}

LabelRequestButtons.propTypes = {
  brigadeLabelLimitIsReached: PropTypes.bool.isRequired,
  collectionLabelLimitIsReached: PropTypes.bool.isRequired,
  handleLabelRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  shippingModelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.object.isRequired,
}

export default LabelRequestButtons
