import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import MainMenuExpanded from './MainMenuExpanded'

const MainMenuSearch = ({ groups, search, mainNavItems }) => {
  const inputRef = useRef(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [onFocusStyle, setOnFocusStyle] = useState('searchNav_content')

  const focusSearch = useCallback((e) => {
    expand()
    inputRef.current.focus()
    e.preventDefault()
    setOnFocusStyle('searchNav_content fullWidth')
  }, [])

  const toggle = useCallback(() => {
    if (isExpanded) {
      collapse()
    } else {
      expand()
    }
  }, [isExpanded])

  const expand = useCallback(() => {
    if (!isExpanded) {
      setIsExpanded(true)
    }
  }, [isExpanded])

  const collapse = useCallback(() => {
    setIsExpanded(false)
    setOnFocusStyle('searchNav_content')
  }, [])

  return (
    <div>
      <div className="searchNav">
        <div className={onFocusStyle}>
          <a className="searchNav_menuButton" href="#" onClick={toggle}>
            <i className="fas fa-bars"></i>
          </a>
          <form action={search.action} method="GET">
            <input
              autoComplete="off"
              className="searchNav_searchInput"
              name="query"
              onFocus={focusSearch}
              placeholder="&#xf002;"
              ref={inputRef}
            />
          </form>
        </div>
      </div>

      <MainMenuExpanded
        groups={groups}
        isShowing={isExpanded}
        mainNavItems={mainNavItems}
      />
    </div>
  )
}

MainMenuSearch.propTypes = {
  groups: PropTypes.array,
  mainNavItems: PropTypes.array,
  search: PropTypes.object
}

export default MainMenuSearch
