import React from 'react'
import PropTypes from 'prop-types'
import LabelButton from './LabelButton'

const LabelButtonGroup = ({
  brigadeLabelLimitIsReached,
  collectionLabelLimitIsReached,
  handleLabelRequest,
  isLoading,
  shippingModelData,
  t,
}) => {
  const isLimitReached =
    brigadeLabelLimitIsReached || collectionLabelLimitIsReached

  return (
    <div className="activeCollection_multipleButtons">
      {isLimitReached ? (
        <div className="d-md-flex align-items-center">
          <button
            className="buttonComponent--disabled buttonComponent--medium flex-shrink-0 me-5"
            disabled
          >
            {t['tcred.profile.my_programs.label_limit_reached.button.label']}
          </button>
          <p className="mediumText grey-text flex-shrink-1 m-0">
            {brigadeLabelLimitIsReached
              ? t[
                  'tcred.profile.my_programs.label_limit_reached.brigade.description'
                ]
              : t[
                  'tcred.profile.my_programs.label_limit_reached.user.description'
                ]}
          </p>
        </div>
      ) : (
        shippingModelData.map((data) => (
          <LabelButton
            data={data}
            handleLabelRequest={handleLabelRequest}
            isLoading={isLoading}
            key={data.id}
            t={t}
          />
        ))
      )}
    </div>
  )
}

LabelButtonGroup.propTypes = {
  brigadeLabelLimitIsReached: PropTypes.bool.isRequired,
  collectionLabelLimitIsReached: PropTypes.bool.isRequired,
  handleLabelRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  shippingModelData: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.object.isRequired,
}

export default LabelButtonGroup
