import React, { useState } from 'react'
import PropTypes from 'prop-types'

const WtnSelector = ({ id, t, wtnPdfPath, wtnIds, dutyCareLink }) => {
  const [isYearSelected, setIsYearSelected] = useState(false)

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value
    setIsYearSelected(selectedValue !== 'createNew')
  }

  const sendWtnPdfEmail = (event, collection) => {
    event.preventDefault()
    if (!isYearSelected) {
      return
    }
    const selectElement = document.getElementById(`${collection}_wtns`)
    const selectValue = selectElement.value
    window.location.href = `${wtnPdfPath}&wtn=${selectValue}`
  }

  if (!wtnIds || wtnIds.length === 0) {
    return null
  }

  return (
    <div>
      <hr />
      <fieldset className="bootstrapForm_fieldset">
        <p className="wtnHint">{t['tcred.profile.my_programs.wtn.hint']}</p>
        <p
          className="wtnHint-2"
          dangerouslySetInnerHTML={{ __html: dutyCareLink }}
        ></p>
        <div className="row">
          <div className="bootstrapForm_formGroup selectWtn col-xl-5">
            <div className="bootstrapForm_label">
              <div className="bootstrapForm_input product-select_wrapper">
                <select
                  defaultValue="createNew"
                  id={`${id}_wtns`}
                  name="wtns"
                  onChange={handleSelectChange}
                >
                  <option id={`${id}_0`} key={`${id}_0`} value="createNew">
                    {t['tcred.profile.my_programs.wtn.placeholder']}
                  </option>
                  {wtnIds.map((item) => (
                    <option
                      id={`${id}_${item.value}`}
                      key={`${id}_${item.value}`}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-12 d-flex align-items-center justify-content-center d-xl-block">
            <a
              className={`readMoreLink text-nowrap py-2 ${!isYearSelected ? 'disabled' : ''}`}
              href="#"
              onClick={(event) => sendWtnPdfEmail(event, id)}
            >
              {t['tcred.profile.my_programs.wtn.url_label']}
            </a>
          </div>
        </div>
      </fieldset>
    </div>
  )
}

WtnSelector.propTypes = {
  dutyCareLink: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  t: PropTypes.object.isRequired,
  wtnIds: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  wtnPdfPath: PropTypes.string.isRequired,
}

export default WtnSelector
